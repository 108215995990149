import { gql } from "graphql-request";
export const FEATURED_NEWS_QUERY = gql`
    query featuredNewsQuery($domainId: Int) {
        allPosts(
            limit: 6
            offset: 0
            published: true
            featured: true
            isDeleted: false
            typeId: 2296
            orderByDesc: createdAt
            domainId: $domainId
        ) {
            id
            domainId
            title
            excerpt
            body
            translations {
                key
                value
                language
            }
            image {
                fullPath
                path
            }
            url {
                id
                modelId
                title
                language
                isActive
                url
            }
            category {
                title
                name
            }
            createdAt
        }
    }
`;
