import { gql } from "graphql-request";
export const VENDOR_COMPARE_GET_DOMAIN_OFFER_QUERY = gql`
    query vendorCompareGetDomainOffers(
        $offerId: Int
        $domainId: Int
        $locale: String
    ) {
        domainOffer(domainId: $domainId, offerId: $offerId, isDeleted: false) {
            offers(id: $offerId) {
                name
                title
                id
                slug
                overview
                quickOverview
                languages
                metatags {
                    key
                    value
                }
                pros(languageEq: $locale) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                cons(languageEq: $locale) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                attributes(isDeleted: false) {
                    value
                    id
                    translations {
                        key
                        value
                        language
                    }
                    baseAttribute {
                        weight
                        comparison
                        name
                        hidden
                        group
                        groupSlug
                        forceComparisonsDisplay
                        type
                        attributesGroup {
                            id
                            slug
                            order
                            name
                            isActive
                        }
                        translations {
                            key
                            value
                            language
                        }
                    }
                }
                mainImage {
                    path
                    fullPath
                }
                faqs(languageEq: $locale, isDeleted: false) {
                    id
                    question
                    answer
                }
                features(languageEq: $locale) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                url(isActive: true, isDeleted: false, languageEq: $locale) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                translations {
                    key
                    value
                    language
                }
                logo {
                    id
                    path
                    fullPath
                }
                categories {
                    name
                    label
                    id
                    languages
                    attributes(
                        hidden: false
                        orderByDesc: weight
                        isDeleted: false
                    ) {
                        type
                        weight
                        value
                        id
                        comparison
                        name
                        hidden
                        group
                        groupSlug
                        forceComparisonsDisplay
                        attributesGroup {
                            id
                            slug
                            order
                            name
                            isActive
                        }
                        translations {
                            key
                            value
                            language
                        }
                    }
                    url(isActive: true, isDeleted: false, languageEq: $locale) {
                        isMain
                        main {
                            url
                        }
                        url
                    }
                }
            }
        }
    }
`;
