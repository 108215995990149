import { gql } from "graphql-request";
export const NEWS_CATEGORIES_QUERY = gql`
    query newsCategoriesQuery($domainId: Int) {
        domain(id: $domainId) {
            categories(
                slugNotIn: [
                    "insurance"
                    "legal"
                    "mortgage"
                    "torts"
                    "autosdsds"
                    "nec"
                    "truvada"
                    "health-insurance"
                    "ozempic"
                    "dna-testing"
                    "personal-injury"
                    "call-center-services"
                    "medicare"
                    "probate"
                    "usa-citizenship"
                    "green-card"
                    "usa-visa"
                    "marketing-services"
                    "divorce"
                    "johnson-and-johnson"
                    "mattresses"
                    "medical-malpractice"
                    "securities"
                    "mva-catch-all"
                    "worker-compensation-insurance"
                ]
            ) {
                categoryChilds {
                    name
                    title
                }
                url {
                    url
                    isMain
                    main {
                        url
                    }
                }
                name
                title
                label
                slug
                posts(typeId: 2296) {
                    id
                }
            }
        }
    }
`;
