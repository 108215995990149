import { gql } from "graphql-request";
export const MORE_NEWS = gql`
    query moreNews($domainId: Int) {
        allPosts(
            published: true
            isDeleted: false
            typeId: 2296
            limit: 1000
            offset: 0
            orderByDesc: createdAt
            domainId: $domainId
        ) {
            title
            translations {
                key
                value
                language
            }
            image {
                fullPath
                path
            }
            url {
                url
                isMain
                main {
                    url
                }
            }
        }
    }
`;
