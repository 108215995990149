import { gql } from "graphql-request";
export const POST_GET_POST_BY_ID = gql`
    query getPostById($id: Int, $domainId: Int) {
        post(id: $id, published: true, domainId: $domainId) {
            excerpt
            title
            body
            categoryId
            languages
            related {
                title
                url {
                    isMain
                    main {
                        url
                    }
                    url
                }
            }
            translations {
                key
                value
                language
            }
            image {
                id
                path
                fullPath
                caption
            }
            tags {
                id
                name
                slug
                isPublished
            }
            urls {
                isMain
                language
                main {
                    url
                }
                url
            }
            url {
                isMain
                language
                main {
                    url
                }
                url
            }
            metatags {
                key
                value
            }
        }
    }
`;
